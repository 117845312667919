.project-new-task-form-card {
    max-width: 800;
    margin: auto;
}

.new-task-description-wrapper {
    border: 1px solid var(--lightgreen);
    border-radius: 10px;
    padding: 5px;
}

.add-project-task-btn {
    background-color: var(--darkgreen);
    color: white;
}

.task-key-autocomplete{
	width: 100%;
}
