@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

:root {
  --darkgreen: #074b59ed;
  --lightgreen: #ddf3f8c3;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.ant-breadcrumb {
  margin-bottom: 20px;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

#logo {
  height: 70px;
}

.ant-btn {
	background-color: var(--darkgreen) !important;
	color: white !important;
}

.ant-btn:hover {
  background-color: var(--darkgreen) !important;
	transform: scale(1.05);
	color: white !important;
}
