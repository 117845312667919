.notification-container {
	max-height: 400px;
	max-width: 300px;
	overflow-y:auto ;
}

.mark-read{
	cursor: pointer;
	color: var(--darkgreen);
	font-size: small;
}

.notification-header{
	display: flex;
	justify-content: space-between;
}

.notification-typography{
	font-size: large;
	font-weight: bolder;
}
.notification-body{
	font-size: 14px;
	font-weight: 500;
}

.notification-card{
	padding: 10px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid lightgray;
	height: 100px;
	cursor: pointer;
	position: relative;
}

.notification-content{
	width: 100%;
	padding: 10px;
	margin-inline-start: 10px;
}

.notification-avatar{
	height: 45px;
	width: 45px;
}

.mention-title{
	font-size: 16px;
	font-weight: bold;
}

.additional-card{
	padding: 10px;
	border-radius: 10px;
	background-color: var(--lightgreen);
}

.notification-action{
	margin-inline: 30px;
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
}

.action-title {
	font-size: small;
	font-weight: bold;
}

.un-read {
	background-color: var(--lightgreen);
}

.notification-time {
	font-size: small;
	color: grey;
	position: absolute;
	top: 10px;
	right: 0;
}
