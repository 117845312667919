.navbar-notification-main-wrapper {
    display: flex;
    justify-content: space-between;
}

.navbar-notification-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}

.logout-btn {
    cursor: pointer;
}


.navbar-belloutlined-icon {
    font-size: 25px;
    margin: 20px;
    cursor: pointer;
}

.navbar-menu-icon {
    font-size: 25px;
    padding: 20px;
    cursor: pointer;
    margin-left: 25px;
}

.logo-container{
	margin-inline: 25px;
	padding: 10px;
}

.ping-typography {
	font-size: 24px;
	margin-inline-start: 5px;
	font-weight: 700;
    cursor: pointer;
}

.logo {
	font-size: 30px;
    cursor: pointer;
}

.settings-content {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.settings-divider {
    margin-block: 5px;
}

.account-wrapper, .logout-wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.account-wrapper:hover, .logout-wrapper:hover {
    background-color: #f9f9f9;
}

@media only screen and (max-width: 450px) 
{
	.navbar-menu-icon {
		padding: 20px;
		margin-left: 0px;
	}

	.notification-container {
		height: 70vh;
		width: 70vw;
	}
}
