.project-members-deletefilled-icon {
    font-size: 20;
    margin: 0px 20px;
}

.invite-user-btn {
    float: right;
    margin: 0px 20px 20px 0px;
    background-color: var(--darkgreen);
    color: white;
}

.invite-form {
    text-align: center;
}


.project-member-space-compact {
    width: 100%;
}

.invite-btn {
    background-color: vat(--darkgreen);
    color: white;
}

.member-avatar-name {
    display: flex;
    align-items: center;
    gap: 20px;
}
