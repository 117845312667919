#sidebar {
	background-color: white;
	z-index: 111;
}

.sidebar-wrapper {
	width: 250px;
	height: 100%;
	border-right: 1px solid lightgrey;
	overflow: auto;
}

.nav-entries {
	margin-top: 25px;
}

#nav-item-custom {
	background-color: inherit;
	height: 50px;
	padding: 15px 20px;
	border-radius: 10px;
	margin: 5px;
}

#nav-item-custom:hover {
	background-color: var(--lightgreen);
}

.nav-item-custom-span {
	font-size: 14px;
	font-weight: 600;
	color: #000;
	margin-left: 20px;
}


.nav-item-active {
	background-color: var(--lightgreen);
}

.nav-item-fixed-style {
	color: inherit;
	text-decoration: none;
}

.nav-item-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.nav-item-disabled:hover {
	cursor: not-allowed;
}

.disable-icon {
	pointer-events: none;
	float: inline-end;
	margin-left: 10px;
	transform: translateY(2px);
}


.nav-header-wrapper {
	margin: 0px 20px;
	background-color: inherit;
}

.nav-header-title {
	font-size: 16px;
	font-weight: 600;
	color: #000;
	margin-left: 15px;
}

.project-title {
	font-size: 20px;
	font-weight: 600;
}

.project-title-div{
	text-align: center;
	padding: 20px;
}
